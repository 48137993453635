import React from "react"
import PageLayout from "../components/page-layout"
import {
  SectionLayout,
  ShortHeroSection,
  AboutUs,
} from "../components/sections"

import { HERO_SECTION_ID, CONTACT_US_ID } from "../constants"

export default function AboutPage() {
  return (
    <PageLayout>
      <ShortHeroSection
        sectionId={HERO_SECTION_ID}
        sectionTitle="Home / About Us"
      />
      <SectionLayout sectionId={CONTACT_US_ID} sectionTitle="About Emelord">
        <AboutUs />
      </SectionLayout>
    </PageLayout>
  )
}
